.container-create-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
  max-width: 100%;
  padding: 50px;
}

.create-court-input {
  color: #000000;
  font-family: Akshar;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  padding-left: 10px;
  margin-bottom: 15px;
}

.create-court-title {
  align-self: center;
  color: #ffffff;
  font-family: Akshar;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 600;
  padding-top: 50px;
}

.create-court-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-left: 20px;
}

input {
  box-sizing: border-box;
  outline: 1px solid #000000;
  height: 35px;
  width: 300px;
  border-radius: 5px;
  margin-bottom: 15px;
}

::placeholder {
  color: #000000;
  font-family: Inter;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  padding-left: 5px;
}

.create-court-label {
  font-family: Akshar;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
}

.create-form-button {
  /* Variables */
  --button_radius: 0.75em;
  --button_color: #e8e8e8;
  --button_outline_color: #000000;
  font-size: 17px;
  font-weight: bold;
  border: none;
  border-radius: var(--button_radius);
  background: var(--button_outline_color);
}

.button-create-form {
  display: block;
  box-sizing: border-box;
  border: 2px solid var(--button_outline_color);
  border-radius: var(--button_radius);
  padding: 0.75em 1.5em;
  background: var(--button_color);
  color: var(--button_outline_color);
  transform: translateY(-0.2em);
  transition: transform 0.1s ease;
}

.create-form-button:hover .button-create-form {
  /* Pull the button upwards when hovered */
  transform: translateY(-0.33em);
}

.create-form-button:active .button-create-form {
  /* Push the button downwards when pressed */
  transform: translateY(0);
}
