.title-court-name {
  color: rgb(39, 34, 34);
  font-size: 3rem;
  margin-bottom: 0px;
}
.container-playing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.container-queue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  padding-bottom: 50px;
}

.label {
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  margin-top: 0px;
  font-family: Akrash;
  font-weight: bold;
}

.status-label {
  width: 20%;
  color: black;
  background-color: rgb(255, 255, 255);
  border: 2px solid black;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}
.court-label {
  width: 30%;
  color: black;
  background-color: rgb(255, 255, 255);
  border: 2px solid black;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}
.player-label {
  width: 40%;
  color: black;
  background-color: rgb(255, 255, 255);
  border: 2px solid black;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}

.end-label {
  width: 30%;
  color: black;
  background-color: rgb(255, 255, 255);
  border: 2px solid black;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}

.table-body {
  background-color: rgb(255, 255, 255);
}

.table-cell {
  color: black;
  border: 2px solid black;
  border-collapse: collapse;
  text-align: center;
  font-size: 0.9rem;
  font: Arial;
}

.position-label {
  width: 80%;
  color: black;
  background-color: rgb(255, 255, 255);
  border: 2px solid black;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}

.initial-label {
  width: 100%;
  color: black;
  background-color: rgb(255, 255, 255);
  border: 2px solid black;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

button {
  /* Variables */
  --button_radius: 0.75em;
  --button_color: #e8e8e8;
  --button_outline_color: #000000;
  font-size: 17px;
  font-weight: bold;
  border: none;
  border-radius: var(--button_radius);
  background: var(--button_outline_color);
}

.button_top {
  display: block;
  box-sizing: border-box;
  border: 2px solid var(--button_outline_color);
  border-radius: var(--button_radius);
  padding: 0.75em 1.5em;
  background: var(--button_color);
  color: var(--button_outline_color);
  transform: translateY(-0.2em);
  transition: transform 0.1s ease;
}

button:hover .button_top {
  /* Pull the button upwards when hovered */
  transform: translateY(-0.33em);
}

button:active .button_top {
  /* Push the button downwards when pressed */
  transform: translateY(0);
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

button {
  /* Variables */
  --button_radius: 0.75em;
  --button_color: #e8e8e8;
  --button_outline_color: #000000;
  font-size: 17px;
  font-weight: bold;
  border: none;
  border-radius: var(--button_radius);
  background: var(--button_outline_color);
}

.button_top {
  display: block;
  box-sizing: border-box;
  border: 2px solid var(--button_outline_color);
  border-radius: var(--button_radius);
  padding: 0.75em 1.5em;
  background: var(--button_color);
  color: var(--button_outline_color);
  transform: translateY(-0.2em);
  transition: transform 0.1s ease;
}

button:hover .button_top {
  /* Pull the button upwards when hovered */
  transform: translateY(-0.33em);
}

button:active .button_top {
  /* Push the button downwards when pressed */
  transform: translateY(0);
}

.end-game {
  background-color: #ffffff;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

table {
  background: #131111;
  padding: 0px;
  width: 95%;
  table-layout: fixed;
}

th {
  height: 40px;
  width: 100%;
  color: white;
  font-family: Akrash;
  text-align: center;
  background-color: #2f2c2c;
}
